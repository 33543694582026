//! Polyfills
// Import polyfills if the browser cannot deal with JS 
if (!('fetch' in window && 'Intl' in window && 'URL' in window && 'Map' in window && 'forEach' in NodeList.prototype && 'startsWith' in String.prototype && 'endsWith' in String.prototype && 'includes' in String.prototype && 'includes' in Array.prototype && 'assign' in Object && 'entries' in Object && 'keys' in Object)) {
  require('./scripts/polyfills');
}

//! Styles
// Import the style which import all the other SCSS files for compiling 
//require('./styles/style.scss')

//! JavaScript
// Import the other JS files in order to compile them 
require('./scripts/script');
require('./scripts/vendor');

//! HTML
// Import the index file in order to compile it as well 
//require('./index.html');








// *** Dev only ***
/*require.context('./styles/base', true)
require.context('./styles/components', true)
require.context('./styles/helpers', true)
require.context('./styles/layout', true)
require.context('./styles/pages', true)
require.context('./styles/vendor', true)*/


/*require('./styles/vendor/reset.scss')
require('./styles/vendor/960_12_col.scss')
require('./styles/pages/index.scss')
require('./styles/components/navbar.scss')
require('./styles/components/button.scss')*/
// *** Dev only ***

