
import Swiper, { Navigation, Pagination, A11y } from 'swiper';
Swiper.use([Navigation, Pagination, A11y]);
/*==========================================================================
********************************** Menu ************************************
==========================================================================*/

const hamburger = document.querySelector(".ham");
const links     = document.querySelectorAll(".menu > .menu-item");
const menus     = document.querySelector(".menus");
const keys      = { 37: 1, 38: 1, 39: 1, 40: 1} ;

if (window.matchMedia("(max-width: 1024px)").matches) {
  hamburger.addEventListener("click", function() {
    menus.classList.toggle("menu-active");
    if(menus.classList.contains("menu-active")) {
    }
    else {
      links.forEach(link => {
        link.classList.remove('opened')
      })
    }
  });

  [...links].map(link => {
    link.addEventListener("click", function() {
      if(link.classList.contains('opened')) {
        link.classList.remove('opened')
      } 
      else {
        links.forEach(link => {
          link.classList.remove('opened')
        })
        link.classList.add('opened')
      }
    })
  });
}

[...document.querySelectorAll('.menu > .menu-item')].forEach(link =>{
  if(link.children.length >= 2){
    link.firstElementChild.appendChild(document.createElement("i")).classList.add("fas", "fa-angle-right");
  }
});


const falcs = document.querySelectorAll(".falcs");

[...falcs].map(falc => {
  falc.addEventListener("click", function() {
    falc.classList.toggle("active");
  });
});

/*==========================================================================
******************************** Swiper ************************************
==========================================================================*/

const diapoAccueil = new Swiper('.diapoAccueil', {
  pagination: { 
    el: ".diapoAccueilPagination"
  },
  a11y: {
    prevSlideMessage: "Diapositive précédente",
    nextSlideMessage: "Diapositive suivante",
    firstSlideMessage: "Première diapositive",
    lastSlideMessage: "Dernière diapositive",
    paginationBulletMessage: "Aller à la diapositive numéro {{index}}",
    containerMessage: "Ceci est un diaporama pour l'en-tête de la page d'accueil",
  },
});

if(document.querySelector('.header_diapo')) {
  const header_diapo = new Swiper('.header_diapo', {
    a11y: {
      prevSlideMessage: "Diapositive précédente",
      nextSlideMessage: "Diapositive suivante",
      firstSlideMessage: "Première diapositive",
      lastSlideMessage: "Dernière diapositive",
      paginationBulletMessage: "Aller à la diapositive numéro {{index}}",
      containerMessage: "Ceci est un diaporama pour l'en-tête de la page",
    },
    navigation: {
      nextEl: '.header_diapo-next',
      prevEl: '.header_diapo-prev',
    },
  });
}
if(document.querySelector('.content_diapo')) {
  const content_diapo = new Swiper('.content_diapo', {
    navigation: {
      nextEl: '.content_diapo-next',
      prevEl: '.content_diapo-prev',
    },
    a11y: {
      prevSlideMessage: "Diapositive précédente",
      nextSlideMessage: "Diapositive suivante",
      firstSlideMessage: "Première diapositive",
      lastSlideMessage: "Dernière diapositive",
      paginationBulletMessage: "Aller à la diapositive numéro {{index}}",
      containerMessage: "Ceci est un diaporama pour l'en-tête de la page",
    },
  });
}

var partenaires = document.getElementsByClassName('partnersPop');
var bgPop = document.getElementById('bgPop');
[...partenaires].forEach(element => {
  element.nextElementSibling.querySelector('.cross').addEventListener('click', function() {
    element.nextElementSibling.classList.remove('openedPop');
    bgPop.classList.remove('openedBg');
    enableScroll();
  });
  window.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
      element.nextElementSibling.classList.remove('openedPop');
      bgPop.classList.remove('openedBg');
      enableScroll();
    }
  })
  element.addEventListener("click", function(){
    element.nextElementSibling.classList.add('openedPop');
    bgPop.classList.add('openedBg');
    disableScroll();
  })
}); 

/*==========================================================================
******************************** Scroll ************************************
==========================================================================*/

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
      e.preventDefault();
  e.returnValue = false;  
}
function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
  }
}
function disableScroll() {
  if (window.addEventListener)
      window.addEventListener('DOMMouseScroll', preventDefault, false);
  document.addEventListener('wheel', preventDefault, {passive: false});
  document.addEventListener('touchmove', preventDefault, {passive: false});
  document.onkeydown  = preventDefaultForScrollKeys;
}
function enableScroll() {
  if (window.removeEventListener)
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
  document.removeEventListener('wheel', preventDefault, {passive: false});
  document.removeEventListener('touchmove', preventDefault, {passive: false});
  document.onkeydown = null;  
}

if(document.getElementById('printOffer')) {
  var printOffer = document.getElementById('printOffer');
  printOffer.addEventListener("click", function(){
    window.print();
  });
}

if(document.querySelector(".more") && document.querySelector(".more-close")) {
  document.querySelector(".more").addEventListener('click', function() {
    document.querySelector(".details").classList.add('visible');
    document.querySelector(".more").classList.add('invisible');
  });
  document.querySelector('.more-close').addEventListener('click', function(){
    document.querySelector(".details").classList.remove('visible');
    document.querySelector(".more").classList.remove('invisible');
  });
}

if(document.querySelector(".show_search")) {
  document.querySelector(".show_search").addEventListener('click', function() {
    document.querySelector(".doc_search").classList.toggle('visible');
    if(document.querySelector(".doc_search").classList.contains('visible')) {
      document.querySelector(".show_search > img").classList.toggle('hide');
      document.querySelector(".show_search > .cross").classList.toggle('hide');
      if (window.matchMedia("(orientation: landscape) and (min-width: 667px)").matches) { disableScroll(); }
    }
    else {
      document.querySelector(".show_search > img").classList.toggle('hide');
      document.querySelector(".show_search > .cross").classList.toggle('hide');
      if (window.matchMedia("(orientation: landscape) and (min-width: 667px)").matches) { enableScroll(); }
    }
  });
}




let nav = document.querySelector(".menu");
let liens = [...nav.querySelectorAll("a")];
liens.map((lien) => {
  if (lien.getAttribute("href") === window.location.href) {
    lien.classList.add("activeLink");
  }
  /*if(lien.parentNode.parentNode.previousElementSibling && lien.parentNode.parentNode.previousElementSibling.tagName === 'A')
    lien.parentNode.parentNode.previousElementSibling.classList.add("activeLink");
  }*/
});


/*==========================================================================
****************** Augmenter et réduire les font-size **********************
==========================================================================*/

if(document.querySelector('.textSize')) {
  var fontMoins = document.querySelector('.textSize > button:first-child');
  var fontPlus  = document.querySelector('.textSize > button:last-child');
  var fontSize  = 1;

  fontMoins.addEventListener("click", function(){
    fontSize = fontSize <= 1 ? 1 : fontSize - .1;
    changeFontSize(fontSize);
  })

  fontPlus.addEventListener("click", function(){
    fontSize = fontSize >= 1.4 ? 1.4 : fontSize + .1;
    changeFontSize(fontSize);
  })

  function changeFontSize(size){
    document.documentElement.style.fontSize = `${size}rem`;
    //body.style.fontSize = `${size}rem`;
  }
}

/*==========================================================================
***************************** Boutons voir + *******************************
==========================================================================*/

var tab = [2, 2, 2];
if(document.getElementById('see-more')) {
  document.getElementById('see-more').addEventListener('click', function() {
    for (let index = 0; index < 3; index++) {
      switch (index) {
        case 0:
          tab[index] = tab[index] + 1;
        break;
        case 1:
          tab[index] = tab[index] + 2;
        break;
        case 2:
          tab[index] = tab[index] + 3;
          tab = [tab[index], tab[index], tab[index]];
        break;
      }
      if(document.querySelector('.articlesAgenda')) {
        var query = `.articlesAgenda > a:nth-child(${tab[index]})`;
        if(document.querySelector(query)) {
          document.querySelector(query).style.display = 'block';
        }
        else {
          document.getElementById('see-more').style.display = 'none';
          document.getElementById('see-no-more').style.display = 'block';
        }
      } else if (document.querySelector('.pressStatements')) {
        var query = `.pressStatements > article:nth-child(${tab[index]})`;
        if(document.querySelector(query)) {
          document.querySelector(query).style.display = 'block';
        }
        else {
          document.getElementById('see-more').style.display = 'none';
          document.getElementById('see-no-more').style.display = 'block';
        }
      } else if (document.querySelector('.newsArticles')) {
        var query = `.newsArticles > article:nth-child(${tab[index]})`;
        if(document.querySelector(query)) {
          document.querySelector(query).style.display = 'block';
        }
        else {
          document.getElementById('see-more').style.display = 'none';
          document.getElementById('see-no-more').style.display = 'block';
        }
      }
    }
  });
}
var tab2 = [5, 5, 5];
if(document.getElementById('see-more-articles')) {
  document.getElementById('see-more-articles').addEventListener('click', function() {
    for (let index = 0; index < 3; index++) {
      switch (index) {
        case 0:
          tab2[index] = tab2[index] + 1;
        break;
        case 1:
          tab2[index] = tab2[index] + 2;
        break;
        case 2:
          tab2[index] = tab2[index] + 3;
          tab2 = [tab2[index], tab2[index], tab2[index]];
        break;
      }
      if (document.querySelector('.newsArticles')) {
        var query = `.newsArticles > article:nth-child(${tab2[index]})`;
        if(document.querySelector(query)) {
          document.querySelector(query).style.display = 'flex';
        }
        else {
          document.getElementById('see-more-articles').style.display = 'none';
          document.getElementById('see-no-more-articles').style.display = 'block';
        }
      }
    }
  });
}


/*==========================================================================
********************************** Dons ************************************
==========================================================================*/

if(document.getElementById('prices')) {
  var lastNameValue = undefined;
  var nameField = undefined;
  var prices = document.getElementById('prices').children;
  
  document.querySelector('.montant_libre').addEventListener('click', function() {
    var timer = setInterval(updateNameDisplay, 500);
    nameField = document.getElementById('montant');
    var timeout = setTimeout(function(){
      console.log(timer);
      clearInterval(timer);
      timer = 0;
    }, 10000);
  });
  
  [...prices].forEach(price => {
    price.addEventListener('click', function() {
      var timer = setInterval(updateNameDisplay, 100);
      nameField = price.querySelector('li > input');
      var timeout = setTimeout(function(){
        console.log(timer);
        clearInterval(timer);
        timer = 0;
      }, 200);
    });
  });
  
  function updateNameDisplay(field = nameField) {
    console.log(field);
    var thisValue = field ? field.value : 0;
    if (lastNameValue != thisValue) {
      document.getElementById('cost').innerHTML = lastNameValue = roundDecimal(thisValue - (thisValue * 0.66)) + '€';
      document.getElementById('reduc').innerHTML = lastNameValue = roundDecimal(thisValue * 0.66) + '€';
    }
  }
  
  function roundDecimal(nombre){
    var precision = 2;
    var tmp = Math.pow(10, precision);
    return Math.round( nombre*tmp )/tmp;
  }
}

setInputFilter(document.getElementById('montant'), function(value) {
  return /^\d*\.?\d*$/.test(value); // Allow digits and '.' only, using a RegExp
});
function setInputFilter(textbox, inputFilter) {
  ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
    textbox.addEventListener(event, function() {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  });
}